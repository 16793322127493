import storage from '../storage';
import { webHookTrack } from './webhookTrack';

export const fireTypWebHookTrack = (prePoppedLink, pageCode) => {
  const formData = storage('session', 'get', 'jtkNetFd') || '';

  const parsedFormData = formData ? JSON.parse(formData) : {};
  const tyLoaded = storage('session', 'get', 'typTimeLoaded');
  const payload = {
    tyLoaded,
    PageCode: pageCode,
    uuid: parsedFormData?.UUID,
    tyPage: true,
    affiliateId: parsedFormData?.CkmAffiliateId,
    offerId: parsedFormData?.CkmOfferId,
    zipcode: parsedFormData?.ZipCode,
    state: parsedFormData?.State,
    buttonClicked: prePoppedLink,
  };

  webHookTrack('TYP_CLICK', payload);
};
