export const fireNetworkCompression = async data => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = urlSearchParams
    ? Object.fromEntries(urlSearchParams.entries())
    : {};

  const compressionData = {
    ...data,
    TypTestVariation: params?.typversion || '',
    TypTestExperimentId: params?.typexpid || '',
  };
  const compressedValue = await window?.jtknet?.networkDataCompression(
    compressionData
  );
  return compressedValue;
};
