import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import storage from '../../utils/storage';
import { webHookTrack } from '../../utils/dynamic/webhookTrack';
import CallButtonIcon from '../../components/ThankyouPages/CallButtonIcon';
import { fireNetworkCompression } from '../../utils/dynamic/fireNetworkCompression';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import { fireTypWebHookTrack } from '../../utils/dynamic/fireWebHookTypTracking';

const EhsdbbWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
  width: 100%;
  max-width: 1170px;
  position: relative;
  margin: 0 auto;
  font-family: 'League Spartan', sans-serif;
  display: grid;
  grid-template-columns: 1fr;
  --phoneNumberFontSize: 30px;
  --clickFontSize: 22px;
  padding: 0 10px;
  /* grid-template-areas:
  'connect'
  'thanks'
  'intouch'
  'list'
  'callButton'
  'logo'; */
  @media (max-width: 600px) {
    --clickFontSize: 18px !important;
    overflow-x: hidden;
    padding: 0 20px;
    --phoneNumberFontSize: 21px;
    --phoneTextTransform: translate(max(88px, min(60%, 186px)), -60%);
    --linkWrapperWidth: max(260px, min(60%, 800px));
    /* grid-template-areas:
    'connect'
    'thanks'
    'intouch'
    'callButton'
    'list'
    'logo'; */
  }
  --phoneTextTransform: translate(max(87px, min(60%, 193px)), -40%);
  svg {
    width: initial !important;
  }

  .connect {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    .phoneContainer {
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-areas: 'phone' 'headline';

      .phoneContainer {
        grid-area: phone;
        width: 70%;
      }
    }
  }
  .connecting {
    font-size: clamp(36px, 6vw, 50px);
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    background: url('https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/bathroomBg-DaBell.jpg')
        center center / 43%,
      #96d701;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-blend-mode: multiply;
    margin: 0;

    @media (max-width: 600px) {
      grid-area: headline;
      margin: 20px 0;
    }
  }
  .thanks {
    font-size: clamp(40px, 6.5vw, 72px);
    font-weight: 700;
    /* grid-area: thanks; */
    color: #676767;
    margin: 0 0 2vh 0;
  }
  .inTouch {
    font-size: clamp(28px, 4.5vw, 50px);
    font-weight: 300;
    color: #676767;
    margin: 0;

    &.special {
      color: #75bc1e;
      font-size: clamp(28px, 4.5vw, 43px);
      font-weight: 700;
      font-family: 'Ubuntu', sans-serif;
    }
  }
  .thanks,
  .inTouch {
    @media (max-width: 600px) {
      text-align: center;
    }
  }
  .listContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #676767;
    margin-top: 30px;
    ul {
      margin-left: 20px;
    }
    li {
      font-size: clamp(1.2em, 2vw, 1.8em);
      margin-bottom: 10px;
      @media (max-width: 600px) {
        margin-bottom: 5px;
      }
    }

    .CtaButton {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr;
      place-items: center;

      button {
        border: none;
        border-radius: 50px;
        width: 90%;
        padding: 20px 10px;
        background: #75bc1e;
        color: #fff;
        font-size: 30px;
        font-weight: 700;
        margin: 0 auto;
        font-family: 'Ubuntu', sans-serif;
        &:hover {
          cursor: pointer;
        }
      }
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-areas: 'button' 'list';
      place-items: center;

      ul {
        grid-area: list;
        margin-top: 20px;
      }

      .CtaButton {
        grid-area: button;
        margin: 20px auto;
        button {
          font-family: 'Ubuntu', sans-serif;
        }
      }
    }
  }
  .callButtonContainer {
    width: 100%;
    margin: -3vh 0 20px;
    /* grid-area: callButton; */
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    @media (max-width: 600px) {
      margin: 0 auto;
      align-items: center;
    }
  }
  .hours {
    font-size: 17px;
    color: #676767;
    margin: 15px auto;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 12px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
  .DaBellaBranding {
    max-width: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    grid-template-areas: 'logo logoText tagline';
    gap: 10px;
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'logoText'
        'logo'
        'tagline';
      gap: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .DaBellaLogo {
    width: 100%;
    @media (max-width: 600px) {
      width: 100px;
      margin: 0 auto;
    }
  }
  .daBellaText {
    grid-area: logoText;
    width: 100%;
    align-self: end;
    margin-left: -15px;
    @media (max-width: 600px) {
      width: 120px;
      margin: 0 auto;
    }
  }
  .daBellaTagline {
    color: #676767;
    grid-area: tagline;
    align-self: end;
    margin: 0 0 -2px 0;
    font-size: 26px;
    font-weight: 300;
    @media (max-width: 600px) {
      width: 100%;
      text-align: center;
    }
  }

  .cantWait {
    display: block !important;
  }

  .febTerms {
    position: absolute;
    bottom: 0;
    color: #666666;
    margin-bottom: 0;
    right: 35px;
    padding: 0 10px;
    font-size: 12px;
    font-family: 'Ubuntu', sans-serif;

    @media (max-width: 600px) {
      position: static;
      text-align: center;
    }
  }
`;

const GreenBar = styled.div`
  background: #75bc1e;
  height: 20px;
  width: 100%;
`;

const EhsdbbThankyou = () => {
  const [FirstName, setFirstName] = useState('');
  const [City, setCity] = useState('');
  const [phoneNumber] = useState('(866) 924-5145');
  const [compressionKey, setCompressionKey] = useState('');
  const [affiliateId, setAffiliateId] = useState('');
  const [febSpecial, setFebSpecial] = useState(false);
  const [formData] = useState(storage('session', 'get', 'jtkNetFd') || '');
  const [parsedFormData] = useState(formData ? JSON.parse(formData) : {});

  const isMobile = window?.innerWidth <= 600;

  useEffect(() => {
    (async () => {
      if (parsedFormData) {
        const data = {
          ...parsedFormData,
          PageCode: 'HSDBB',
        };
        setFirstName(parsedFormData?.FirstName || '');
        setCity(parsedFormData?.City || '');
        setAffiliateId(parsedFormData?.CkmAffiliateId || '');

        const key = await fireNetworkCompression(data);
        setCompressionKey(key);
      }
    })();

    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    if (month < 3 && year === 2024) {
      setFebSpecial(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = async () => {
    const prePoppedLink = `https://trkpls2.com/?r3x=XBamkUU1kbiFnGkDYWouVOcV2pp1jy6g&s1=${compressionKey}&s2=s1=${compressionKey}&s3=${affiliateId}`;

    fireTypWebHookTrack(prePoppedLink, 'HSBDD');

    navigate(prePoppedLink);
  };

  return (
    <>
      <GreenBar />
      <EhsdbbWrapper>
        <div className="connect">
          <p className="connecting">We look forward to connecting!</p>
          <div className="phoneContainer">
            <CallButtonIcon phoneNumber={phoneNumber} PageCode="HSDBB" />
            <p className="hours">
              Monday-Friday: 7am-9pm PST <br />
              Saturday: 8am-5pm PST / Sunday Closed
            </p>
          </div>
        </div>

        <p className="thanks">Thanks{FirstName ? `, ${FirstName}` : ''}!</p>

        {febSpecial ? (
          <p className="inTouch special">
            {isMobile ? (
              <>
                $0 Down Payment <br /> AND <br /> No Payments Until 2025!*
              </>
            ) : (
              '$0 Down Payment AND No Payments Until 2025!*'
            )}
          </p>
        ) : (
          <p className="inTouch">
            A DaBella rep {City ? `in ${City}` : ''} will be in touch soon!
          </p>
        )}

        <div className="listContainer">
          <ul>
            <li>Five-star customer experience</li>
            <li>A variety of financing options</li>
            <li>Free consultations, on your schedule</li>
          </ul>
          <div className="CtaButton">
            <button type="button" onClick={() => handleClick()}>
              Get My Quote
            </button>
          </div>
        </div>

        <div className="RedirectContainer" />

        <div className="DaBellaBranding">
          <div className="DaBellaLogo">
            {' '}
            <svg
              viewBox="0 0 600 427"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2196_9)">
                <path
                  d="M314.397 173.537C314.281 157.074 314.298 140.595 313.918 124.149C313.818 120 315.868 118.628 319.041 116.992C375.008 88.248 430.926 59.3885 486.86 30.5621C504.86 21.2893 522.86 12.0497 540.86 2.77695C542.033 2.18191 543.455 1.78522 543.686 0.132324H546.992C552.116 1.57034 556.165 4.89265 560.463 7.78522C571.389 15.1736 581.802 23.2893 592.694 30.7108C595.57 32.6612 598.926 34.529 598.992 38.7935C595.736 58.6612 588.413 77.3885 582.661 96.5455C571.951 132.281 561.041 167.984 550.116 203.653C548.496 208.975 545.637 212.645 541.207 215.901C518.876 232.331 496.298 248.413 474.232 265.19C463.256 273.141 452.331 281.157 441.537 289.355C439.587 290.843 437.62 292.298 435.008 292.397C431.025 292.479 428.48 289.884 425.951 287.355C390.43 251.901 354.959 216.397 319.438 180.942C317.273 178.777 315.405 176.463 314.38 173.554L314.397 173.537Z"
                  fill="#95D600"
                />
                <path
                  d="M54.6442 0.115723C72.4789 11.3885 91.8012 19.8843 110.446 29.6033C166.611 58.8926 222.942 87.8678 279.256 116.893C282.297 118.463 284.694 119.719 284.595 123.983C284.231 140.479 284.281 156.975 284.181 173.471C282.595 177.041 279.983 179.818 277.256 182.545C242.925 216.826 208.628 251.157 174.281 285.405C171.256 288.43 168.628 292.347 163.504 292.116C154.661 288.926 148.049 282.298 140.545 277.091C134.991 273.24 129.652 269.058 124.214 265.008C105.157 250.579 85.9831 236.298 66.3798 222.628C56.0657 215.438 49.2393 207.074 45.5368 194.496C31.9004 148.397 17.9335 102.397 3.56985 56.5289C1.83432 51.0083 2.2806 45.124 0.131836 39.7521V36.4463C6.71035 28.4959 15.9004 23.8513 24.0492 17.9835C32.826 11.6364 40.9418 4.06614 51.3384 0.115723H54.6442Z"
                  fill="#95D600"
                />
                <path
                  d="M163.686 426.248C143.355 405.736 123.124 385.141 102.628 364.81C98.81 361.025 97.1736 357.289 97.2728 351.901C97.686 332.099 97.6199 312.298 97.7356 292.496C100.546 290.562 103.703 289.851 107.058 289.901C125.884 290.215 144.727 288.876 163.521 290.909C167.636 290.727 169.554 287.339 172 284.893C207.19 249.802 242.314 214.645 277.488 179.521C279.62 177.405 281.934 175.471 284.165 173.471C284.281 216.628 284.331 259.785 284.595 302.942C284.628 307.223 282.083 309.355 279.603 311.851C241.521 349.984 203.422 388.116 165.339 426.232H163.686V426.248Z"
                  fill="#74BB1E"
                />
                <path
                  d="M500.843 292.331C500.991 313.421 501.091 334.529 501.372 355.62C501.421 359.802 498.727 362.017 496.231 364.512C475.702 385.091 455.157 405.669 434.628 426.248H432.975C402.958 396.05 372.925 365.868 342.925 335.669C333.438 326.116 320.264 318.05 315.553 306.545C310.727 294.744 314.049 279.554 314.049 265.851C314.082 235.091 314.264 204.314 314.396 173.554C351.702 210.694 388.975 247.835 426.314 284.926C428.777 287.372 430.677 290.744 434.793 290.926C452.81 289.025 470.892 290.132 488.925 289.868C493.074 289.802 497.206 289.868 500.843 292.331Z"
                  fill="#74BB1E"
                />
                <path
                  d="M500.843 292.331C480.843 292.38 460.843 292.43 440.859 292.446C438.743 292.446 436.396 293.058 434.793 290.926C447.322 282.033 459.851 273.157 472.363 264.265C480.413 271.802 488.529 279.273 496.479 286.926C498.115 288.496 500.942 289.306 500.843 292.331Z"
                  fill="#4C8D19"
                />
                <path
                  d="M163.52 290.909C161.074 293.587 157.868 292.38 155.025 292.397C135.934 292.512 116.826 292.479 97.7354 292.496C103.421 283.603 112.562 278.182 119.752 270.81C121.851 268.661 123.95 266.512 126.049 264.347C138.545 273.207 151.025 282.05 163.52 290.909Z"
                  fill="#4C8D19"
                />
              </g>
              <defs>
                <clipPath id="clip0_2196_9">
                  <rect width="600" height="426.446" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="daBellaText">
            {' '}
            <svg
              viewBox="0 0 741 162"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2197_21)">
                <path
                  d="M740.79 157.89C731.63 157.93 722.47 157.82 713.32 158.1C709.77 158.21 708.52 157.1 708.93 153.62C709.17 151.56 708.97 149.44 708.97 146.46C706.35 148.53 704.49 150.09 702.54 151.52C673.99 172.46 632.5 157.64 621.93 122.57C616.12 103.3 617.13 84.59 627.69 66.9C644.15 39.34 683.66 33.06 706.46 53.75C706.94 54.18 707.47 54.56 707.96 54.96C708.31 54.73 708.76 54.58 708.78 54.39C709.32 48.83 712.496 46.05 718.31 46.05C725.8 46.05 733.3 46.05 740.79 46.05V157.9V157.89ZM679.49 131.1C695.78 131.07 708.6 118.07 708.67 101.52C708.74 84.59 695.98 71.46 679.43 71.41C663.55 71.36 650.97 84.44 650.96 101.02C650.95 118.12 663.29 131.13 679.49 131.1Z"
                  fill="#66685D"
                />
                <path
                  d="M459.91 161.88C454.97 160.78 449.93 159.99 445.1 158.52C423.33 151.86 410.38 136.98 406.36 114.84C402.5 93.5801 406.66 74.0501 422.46 58.3301C434.72 46.1301 450.24 41.6701 467.14 42.3201C485.65 43.0301 501.47 49.6801 512.17 65.6501C521.36 79.3701 522.72 94.6601 520.95 110.58C520.59 113.85 518.39 113.05 516.58 113.06C504.76 113.11 492.94 113.09 481.12 113.09C466.97 113.09 452.82 113.09 438.03 113.09C440.35 121.91 445.33 128 453.36 131.44C463.84 135.93 474.51 134.72 485.07 131.75C490.33 130.27 495.55 128.33 500.07 125.23C503.15 123.11 504.31 124.46 505.67 126.93C508.55 132.16 511.32 137.46 514.55 142.47C516.29 145.17 515.65 146.41 513.25 147.98C502.05 155.29 489.8 159.52 476.55 161.01C475.32 161.15 473.84 160.43 472.92 161.9H459.93L459.91 161.88ZM438.24 89.8401C454.73 89.8401 471.16 89.8201 487.59 89.8601C490.35 89.8601 489.96 88.4601 489.46 86.6401C486.43 75.6201 475.7 68.4401 463.02 68.9801C450.59 69.5101 440.37 77.9801 438.24 89.8301V89.8401Z"
                  fill="#66685D"
                />
                <path
                  d="M562.87 0.0998437C562.82 29.0098 562.75 57.9198 562.71 86.8298C562.68 109.43 562.63 132.04 562.78 154.64C562.8 158.11 561.87 159.22 558.35 159.1C550.54 158.85 542.71 158.8 534.9 159.11C530.92 159.27 529.95 158.03 529.96 154.13C530.09 103.77 530.05 53.4098 530.04 3.04984C530.04 2.05984 529.94 1.07984 529.88 0.0898438H562.87V0.0998437Z"
                  fill="#66685D"
                />
                <path
                  d="M579.04 1.1001C578.98 0.770098 578.92 0.430098 578.86 0.100098C589.52 0.100098 600.18 0.100098 610.85 0.100098C610.79 0.430098 610.73 0.770098 610.67 1.1001C600.13 1.1001 589.58 1.1001 579.04 1.1001Z"
                  fill="#66685D"
                />
                <path
                  d="M0.970358 82.4701C0.970358 58.5201 1.05036 34.5801 0.890358 10.6301C0.860358 6.80007 1.77036 5.55007 5.80036 5.60007C26.4304 5.89007 47.0904 5.28007 67.7004 6.08007C105.42 7.54007 131.54 33.7501 134.08 71.6601C135.24 88.9301 134.22 105.86 126.33 121.72C115.61 143.27 97.7004 154.93 74.2704 157.81C50.9304 160.67 27.3904 158.44 3.95036 159.07C0.350358 159.17 0.970358 156.9 0.960358 154.81C0.960358 130.7 0.960358 106.58 0.960358 82.4701H0.970358ZM34.9204 82.2201C34.9204 95.5201 35.0104 108.82 34.8504 122.12C34.8104 125.21 35.7104 126.09 38.7804 126C46.9304 125.76 55.1004 126.16 63.2304 125.73C75.3804 125.1 85.3304 120.11 91.8804 109.48C99.8304 96.5701 101 82.6101 97.4404 68.2201C93.6704 52.9601 84.6504 42.4101 68.5704 39.6001C58.2104 37.7901 47.6504 39.1601 37.1904 38.9001C34.0904 38.8201 34.9604 41.2201 34.9504 42.8201C34.8904 55.9501 34.9204 69.0901 34.9204 82.2201Z"
                  fill="#66685D"
                />
                <path
                  d="M234.01 146.17C226.25 153.09 218.63 157.95 209.51 159.97C187.87 164.75 165.47 155.94 153.52 136.34C139.93 114.06 139.47 90.7399 152.19 67.7399C167.14 40.6899 207.8 31.1899 233.38 55.2999C233.57 55.0799 233.91 54.8699 233.93 54.6499C234.197 48.9099 237.227 46.0399 243.02 46.0399C249.52 46.0399 256.01 46.1499 262.51 45.9899C265.16 45.9299 265.89 46.8399 265.89 49.4199C265.82 84.5299 265.81 119.64 265.89 154.75C265.89 157.49 264.83 158.09 262.36 158.06C254.37 157.95 246.37 157.84 238.38 158.1C234.89 158.21 233.53 157.24 233.98 153.7C234.24 151.6 234.03 149.45 234.03 146.17H234.01ZM204.57 131.09C221.05 131.09 233.68 117.95 233.61 100.88C233.55 84.5799 220.78 71.5299 204.76 71.3999C188.45 71.2599 176 84.1299 175.94 101.18C175.88 118.13 188.29 131.09 204.57 131.09Z"
                  fill="#66685D"
                />
                <path
                  d="M579.04 1.1001C589.58 1.1001 600.13 1.1001 610.67 1.1001C610.77 2.7501 610.97 4.4001 610.97 6.0501C610.99 55.2701 610.91 104.49 611.1 153.71C611.12 158.2 609.93 159.34 605.59 159.12C598.29 158.75 590.94 158.81 583.63 159.1C579.77 159.26 578.61 158.22 578.64 154.21C578.82 124.45 578.74 94.6801 578.74 64.9201C578.74 45.4601 578.74 26.0101 578.75 6.5501C578.75 4.7301 578.94 2.9201 579.05 1.1001H579.04Z"
                  fill="#66685D"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M376.25 76.7005C374.818 76.2239 373.383 75.7461 372.26 74.59C384.47 67.59 390.7 57.62 391.4 45.67C392.63 24.73 376.71 7.21997 354.45 6.21997C338.585 5.50397 322.685 5.57471 306.785 5.64545C298.702 5.68141 290.618 5.71738 282.54 5.64997C279.39 5.61997 279.12 7.10997 279.12 9.60997C279.16 58.19 279.17 106.76 279.1 155.34C279.1 158.1 279.81 159.1 282.72 159.08C291.455 159.029 300.19 159.045 308.925 159.061C324.338 159.09 339.748 159.118 355.15 158.78C379.26 158.24 397.69 140.93 399.28 118.37C400.62 99.38 393.18 84.86 378.48 77.56C377.769 77.2062 377.01 76.9535 376.25 76.7005ZM326.925 129.067H326.923L326.915 129.067C323.513 129.04 320.106 129.013 316.71 129.14C314.505 129.223 313.311 128.839 313 126.986V119.88C313.059 114.172 313.057 108.461 313 102.75V95.2869C313.278 93.5657 314.298 93.0095 316.59 93.05C320.746 93.124 324.903 93.106 329.06 93.0881C333.893 93.0672 338.726 93.0463 343.56 93.17C351.56 93.37 358.83 95.51 362.49 103.58C367.86 115.45 360.21 127.73 346.65 128.89C343.135 129.192 339.588 129.112 336.04 129.033C334.419 128.996 332.798 128.96 331.18 128.96V129.09C329.763 129.09 328.344 129.079 326.925 129.067ZM313 62.5773C313.208 63.0975 313.48 63.5597 313.83 63.95C314.237 64.4053 314.72 64.7474 315.26 65H325.392C326.266 64.924 327.083 64.8959 327.8 64.97V64.98C329.026 64.98 330.196 64.9886 331.32 65H341.083C341.563 64.9843 342.042 64.9645 342.52 64.94C350.81 64.51 357.31 58.27 357.77 50.59C358.171 43.8373 353.573 38.0243 346.128 36H313.878C313.845 36.0326 313.812 36.066 313.78 36.1C313.455 36.4417 313.199 36.8276 313 37.25V48.2965C313.009 48.558 313.012 48.8161 313.01 49.07C313.009 49.1124 313.008 49.155 313.008 49.1977L313.007 49.2536C313.005 49.3455 313.003 49.4379 313 49.5309V62.5773Z"
                  fill="#66685D"
                />
              </g>
              <defs>
                <clipPath id="clip0_2197_21">
                  <rect width="741" height="162" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <p className="daBellaTagline">Quality begins at home</p>
        </div>
        {febSpecial && (
          <p className="febTerms">
            *Terms and conditions apply. Offer ends February 29th
          </p>
        )}
      </EhsdbbWrapper>
    </>
  );
};

export default withThankyouWrapper(EhsdbbThankyou);
